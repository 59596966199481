<template lang="html">
  <div class="account">
    <div class="box">
      <div class="list">
        <div class="left">
          手机号
        </div>
        <div class="right">
          {{userInfo.merchantsContact}}
        </div>
      </div>
      <div class="list" @click="$router.push({path: '/account/changePassword'})">
        <div class="left">
          密码修改
        </div>
        <div class="right">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'account',
    data() {
      return {
        userInfo: {}
      };
    },
    created() {
      let userInfo = localStorage.getItem('propertyUserInfo') || '';
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .account {
    padding: 30px 24px 0 24px;
    .box {
      box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
      border-radius: 30px;
      .list {
        height: 140px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 28px;
        padding: 0 20px;
        .left {
          color: #000000;
        }
        .right {
          color: #333333;
          .van-icon {
            color: #B9B9B9;
          }
        }
      }
    }
  }
</style>
